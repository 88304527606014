import { Fragment } from "react";
import Link from "next/link";
import {
  ChevronRightIcon,
  PhoneIcon,
  HomeIcon,
} from "@heroicons/react/outline";
import { brand_data } from "../utils/data/brands";

//Components
import Layout from "../components/layout/general";

//Hooks
import { useAuth } from "../lib/hooks/auth";

const collections = [
  {
    name: "Compressors",
    href: "/equipment/compressor",
    imageSrc: "/collections/compressors.jpg",
    imageAlt: "Close up compressor board",
    description:
      "Featuring the Empirical Labs Distressor®, the product that started it all.",
  },
  {
    name: "Studio Monitors",
    href: "/equipment/studio monitor",
    imageSrc: "/collections/studio_monitors.jpg",
    imageAlt: "Multiple Studio Monitors",
    description: "Strauss Elektroakustik mastering monitors from Switzerland.",
  },
  {
    name: "Microphone Preamps",
    href: "/equipment/mic preamp",
    imageSrc: "/collections/quag 501 rack.jpg",
    imageAlt:
      "Quagliardi Audio 501+ preamp shown rack mounted.",
    description:
      "Featuring preamps from brands like Quagliardi and Useful Arts",
  },
];

const products = [
  {
    brand: "Empirical Labs",
    url: "distressorX",
    name: "EL8X Distressor",
    price: 1979,
    img: "/products/distressor x1.png",
  },
  {
    brand: "Serpent Audio",
    name: "Splice MKII",
    url: "SPLICE-SA-76",
    price: 1999,
    img: "/products/serp splice 2.png",
  },
  {
    brand: "Useful Arts",
    url: "BF1",
    name: "BF-1",
    price: 799,
    img: "/products/useful bf1 4.png",
  },
  {
    brand: "Whitestone",
    url: "WAI-P331-WH",
    name: "P331",
    price: 5999,
    img: "/products/whitestone 2.png",
  },
];

const Content = () => {
  const { authObject } = useAuth();
  return (
    <Fragment>
      <section id="hero" className="max-w-7xl mx-auto">
        <div className="flex flex-col border-b border-gray-200 lg:border-0">
          <div className="relative">
            <div
              aria-hidden="true"
              className="hidden absolute w-1/2 h-full bg-white lg:block"
            />
            <div className="relative bg-gray-100 lg:bg-transparent">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:grid lg:grid-cols-2">
                <div className="max-w-2xl mx-auto py-24 lg:py-64 lg:max-w-none">
                  <div className="lg:pr-16">
                    {/* <div className="mb-3">
                      <a href="#" className="inline-flex space-x-4">
                        <span className="rounded bg-blue-50 px-2.5 py-1 text-xs font-semibold text-primary tracking-wide uppercase">
                          What's new
                        </span>
                        <span className="inline-flex items-center text-sm font-medium text-primary space-x-1">
                          <span>Free shipping within the U.S.</span>
                          <ChevronRightIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </span>
                      </a>
                    </div> */}
                    <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl mx-auto ">
                      <span className="block xl:inline">Wave Distro</span>{" "}
                      <br />
                      <span className="block text-primary xl:inline text-3xl sm:text-4xl md:text-5xl lg:text-4xl xl:text-4xl font-normal uppercase">
                        In the mix since '96
                      </span>
                    </h1>
                    <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
                      Professional audio distribution made easy. Providing
                      artists, music stores, pro audio dealers and international
                      audio distributors with the finest audio products
                      available.
                    </p>
                    <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
                      <div className="rounded-md shadow">
                        {authObject ? (
                          <Link href="/dashboard">
                            <span className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary hover:bg-primaryDark md:py-4 md:text-lg md:px-10">
                              <HomeIcon
                                className="-ml-1 mr-3 h-5 w-5"
                                aria-hidden="true"
                              />
                              Dashboard
                            </span>
                          </Link>
                        ) : (
                          <Link href="/signup">
                            <span className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary hover:bg-primaryDark md:py-4 md:text-lg md:px-10">
                              Create Account
                            </span>
                          </Link>
                        )}
                      </div>
                      <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                        <Link href="/contact">
                          <span className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-primary bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10">
                            <PhoneIcon
                              className="-ml-1 mr-3 h-5 w-5"
                              aria-hidden="true"
                            />
                            Contact Sales
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full h-48 sm:h-64 lg:absolute lg:top-0 lg:right-0 lg:w-1/2 lg:h-full hidden lg:block py-12 pr-0 lg:pr-8">
              <img
                src="/banners/rack.jpg"
                alt="Wave Distro Logo"
                className="w-full h-full object-center object-cover rounded-lg"
              />
            </div>
          </div>
        </div>
      </section>

      <section
        id="featured_products"
        className="relative bg-gray-50 pt-16 sm:pt-24 lg:pt-24"
      >
        <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
          <div>
            <h2 className="text-base font-semibold tracking-wider text-primary uppercase">
              Recommendations
            </h2>
            <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
              Customer Favorites:
            </p>
            {/* <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
              Make sure to contact our sales department for pricing options.
            </p> */}
          </div>
          <div className="container px-5 pt-12 pb-24 mx-auto">
            <div className="flex flex-wrap -m-4">
              {products.map((item) => (
                <div
                  className="lg:w-1/4 md:w-1/2 p-4 w-full group"
                  key={item.name}
                >
                  <div className="block relative h-48 rounded-md overflow-hidden shadow px-4 bg-white">
                    <img
                      alt={item.name}
                      className="object-cover object-center w-full h-full block"
                      src={item.img}
                    />
                    <div className="absolute inset-0 bg-gray-300 opacity-0 group-hover:opacity-75 pointer-events-none group-hover:pointer-events-auto overflow-hidden rounded transition-all duration-300" />
                    <div className="absolute opacity-0  group-hover:opacity-100 flex justify-center items-center inset-0 group transition-opacity duration-300">
                      <Link href={`/${item.url}`}>
                        <span className="bg-white hover:bg-blue-200 rounded-full px-3 py-3 flex items-center transition-all mr-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="h-6 w-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1"
                              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM10 7v3m0 0v3m0-3h3m-3 0H7"
                            />
                          </svg>
                        </span>
                      </Link>
                    </div>
                  </div>
                  <div className="mt-4 flex justify-between items-center text-left">
                    <div>
                      <h3 className="text-gray-500 text-xs tracking-widest title-font mb-1 uppercase">
                        {item.brand}
                      </h3>
                      <h2 className="text-gray-900 title-font text-lg font-medium">
                        {item.name}
                      </h2>
                    </div>
                    <p className="mt-1 text-lg">{`$${item.price}`}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Collection section */}
      <section
        aria-labelledby="collection-heading"
        className="max-w-xl mx-auto pt-8 px-4 sm:pt-16 sm:px-6 lg:max-w-7xl lg:px-8 pb-16"
      >
        <h2
          id="collection-heading"
          className="text-2xl font-extrabold tracking-tight text-gray-900"
        >
          Shop by Equipment
        </h2>
        <p className="mt-4 text-base text-gray-500">
          The best of analog signal processing equipment from the USA and
          Europe.
        </p>

        <div className="mt-10 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
          {collections.map((collection) => (
            <a
              key={collection.name}
              href={collection.href}
              className="group block"
            >
              <div
                aria-hidden="true"
                className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden group-hover:opacity-75 lg:aspect-w-5 lg:aspect-h-6"
              >
                <img
                  src={collection.imageSrc}
                  alt={collection.imageAlt}
                  className="w-full h-full object-center object-cover"
                />
              </div>
              <h3 className="mt-4 text-lg font-semibold text-gray-900">
                {collection.name}
              </h3>
              <p className="mt-2 text-sm text-gray-500">
                {collection.description}
              </p>
            </a>
          ))}
        </div>
      </section>

      <section aria-labelledby="logo-cloud" className="bg-accentBlue">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div>
              <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                Trusted by the biggest names in the business
              </h2>
              <p className="mt-3 max-w-3xl text-lg text-gray-500">
                Over 25 years we've created and maintained relationships with
                the worlds most trusted brands. We pride ourselves on providing
                the best equipment to our customers to help them achieve their
                goals.
              </p>
              {/* <div className="mt-8 sm:flex">
                <div className="rounded-md shadow">
                  <a
                    href="#"
                    className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                  >
                    Create Account
                  </a>
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-3">
                  <a
                    href="#"
                    className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200"
                  >
                    Contact Us
                  </a>
                </div>
              </div> */}
            </div>
            <div className="mt-8 grid grid-cols-3 gap-1 md:grid-cols-3 lg:mt-0 lg:grid-cols-3 lg:mx-12">
              {brand_data.map((brand, i) => (
                <div
                  key={i}
                  className="col-span-1 flex justify-center py-8 px-4 bg-gray-100"
                >
                  <img className="max-h-12" src={brand.logo} alt="Workcation" />
                </div>
              ))}
              {/* Two placeholder brands */}
              <div className="col-span-1 flex justify-center py-8 px-4 bg-gray-100">
                <img
                  className="max-h-12"
                  src="/brands/neoden.png"
                  alt="Neoden USA"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

const Home = () => {
  return (
    <Layout>
      <Content />
    </Layout>
  );
};

export default Home;
